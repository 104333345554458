<template>
  <section class="experience" data-aos="fade-up">
    <div class="__content my-3" v-for="section in data.sections" :key="section.id">
      <div class="__description">
        <h2><b>{{ section.title }}</b></h2>
        <p>{{ section.description }}</p>
      </div>
      <img class="w-100 d-none d-md-block my-4" :src="require(`../../assets/${section.img}`)" :alt="`Imagen de seccion ${section.title}`">
      <img class="w-100 d-block d-md-none my-4" :src="require(`../../assets/${section.imgMobile}`)" :alt="`Imagen de seccion ${section.title} version movil`">
    </div>
  </section>
</template>

<script>
import store from '../../store';
export default {
  name: "Experiencia",
  data() {
    return {
      data: {
        sections: [
          {
            id: 1,
            title: 'Experiencia',
            description: 'Empresas que el equipo de Wetforest ha creado o ha estado vinculado, ganando experiencia, desarrollando negocios.',
            img: 'img/experience-desktop.webp',
            imgMobile: 'img/experience-mobile.webp'
          },
          {
            id: 2,
            title: 'Portafolio',
            description: 'Algunos de nuestros clientes.\n',
            img: 'img/portfolio-desktop.webp',
            imgMobile: 'img/portfolio-mobile.webp'
          }
        ]
      },
    }
  },
  mounted() {
    
    this.fetchData();
  },
  watch: {
    "$store.state.sections" : function() {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
       if (store.state.sections.expertise) {
      this.data = store.state.sections.expertise.components;}
    }
  }
}
</script>

<style scoped lang="scss">
.experience {
  align-items: center;
  background-image: url("../../assets/img/x-horizontal-wave.svg");
  background-size: contain;
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  padding: 5% 0;
  width: 100%;

  .__description {
    align-self: center;
    width: 100%;
    max-width: 70%;

    h3 {
      margin: 35px 0;
    }
  }

  .__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1366px;
    text-align: center;
    padding: 20px;
  }
}

@media (min-width: 992px) {

}
</style>
